@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import 'variables';

body {
  background-color: $favColor;
}

.card-container {
  display: flex;
  flex-flow: row wrap;
  margin-top: 60px;
  justify-content: center;

  .card {
    width: 350px;
    height: auto;
    margin: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .card-body {
    flex: none;
  }
}

.card-container-2 {
  display: flex;
  flex-flow: row wrap;
  margin-top: 120px;
  justify-content: center;

  .tech-image {
    width: 60px;
    height: auto;
    padding: 5px;
  }

  .card {
    width: 125px;
    margin: 15px;
    height: 10em;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .card-body {
    flex: none;
  }
}

.about-content{
  padding: 5% 10%;
  color: white;
}

.about-content-h5 {
  margin: 10px;
}

.image-container {
  display: flex;
  justify-content: center;
  align-content: center;
}

.profile-image {
  margin-top: 130px;
  border-radius: 20%;
  height: 300px;
  border: black 20px;
}

.project-form {
  margin-top: 60px;
}

.technology-form {
  margin-top: 60px;
}

li {
  list-style: none;
}
